import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { downloadUrls } from '@config/settings'
import { View } from 'react-native-animatable'
import { Trans } from '@lingui/react'

const downloadIcons = {
  android: require('../images/downloadIcons/google-play.svg'),
  ios: require('../images/downloadIcons/appstore.svg'),
}

const styles = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    marginTop: 50,
    textAlign: 'center',
  },
  logo: {
    width: 150,
    height: 150,
    maxWidth: '34vw',
    maxHeight: '34vw',
  },
} as any
const IndexPage = (props: any) => (
  <View animation="fadeInUp">
    <div style={styles.box}>
      <Img style={styles.logo} {...props.data.logo} />
      <p style={styles.description}>
        <Trans>The app for enjoy products.</Trans>
        <br />
        <Trans>Make shopping easy.</Trans>
      </p>
      <div
        style={{
          marginTop: '4rem',
          minWidth: 320,
          width: '50%',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 320 * 0.05,
        }}
      >
        <a href={downloadUrls.ios}>
          <img src={downloadIcons.ios} />
        </a>
        <a href={downloadUrls.android}>
          <img src={downloadIcons.android} />
        </a>
      </div>
    </div>
  </View>
)

export default IndexPage

export const pageQuery = graphql`
  query indexQuery($language: String!) {
    logo: imageSharp(fluid: { originalName: { regex: "/logo.png/" } }) {
      fluid(maxWidth: 150) {
        # ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluid_noBase64
      }
    }
    ...FooterFragment
  }
`
